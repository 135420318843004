import $ from 'jquery-slim';

class OptDropdownSelect
{
    constructor($element)
    {
        this.$element = $element
        this.$toggle = this.$element.find('.dropdown-toggle');

        this.$options = this.$element.find('.dropdown-item');

        for (let i = 0; i < this.$options.length; i++) this.$options.eq(i).on('click', this.select.bind(this, i));

        let index = this.$element.attr('index');
        if (index === '' || isNaN(index)) index = 0;

        this.select(index);
    }

    select(index)
    {
        this.$element.attr('index', index);
        this.$toggle.text(this.$options.eq(index).text());
        this.index = index;

        this.$element.trigger('change', {index:index});
    }
}

class OptDropdown
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        this.$selects = $('.dropdown-select');

        this.selects = [];

        for (let i = 0; i < this.$selects.length; i++) this.selects.push(new OptDropdownSelect(this.$selects.eq(i)));

        $('.dropdown:not(.dropdown-select) .dropdown-menu').click(function(e) {
            if (!$('.btn-filter').is(e.target)) e.stopPropagation();
        });
    }


}

const dropdown = new OptDropdown();